import { useTranslation } from 'react-i18next';
import { supportedLngs } from '@/app/i18n/i18n';

export default function LocaleSwitcher() {
  const { i18n } = useTranslation();

  return (
        <select
          value={i18n.resolvedLanguage}
          onChange={(e) => i18n.changeLanguage(e.target.value)}
        >
          {Object.entries(supportedLngs).map(([code, name]) => (
            <option value={code} key={code}>
              {name}
            </option>
          ))}
        </select>
  );
}
