export const fallbackLng = 'fi';
export const languages = [fallbackLng, 'en'];
export const defaultNS = 'translation';

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    returnEmptyString: false, // To no return empty translations added f.e from BabelEdit
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    defaultNS,
    ns,
    interpolation: {
      escapeValue: false, // Already done by react
    },
  };
}
