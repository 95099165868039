import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import Link from 'next/link';
import { Box, Button, Center, Image, Flex, Text, Heading, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { FooterBar } from '../layout/FooterBar';

function HomePage() {
  const { t } = useTranslation();

  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    console.log('root status: ', status);
    if (!(status === 'unauthenticated' || status === 'loading')) {
      router.push('/dashboard');
    }
  });

  if (status === 'loading') {
    return (
      <Center>
        <p>Loading...</p>
      </Center>
    );
  }

  if (status === 'unauthenticated') {
    const cognitoLink = process.env.NEXT_PUBLIC_COGNITO_SIGNUP_URL || '';
    const cognitoLoginLink = process.env.NEXT_PUBLIC_COGNITO_SIGNIN_URL || '';
    const cognitoLogoutLink = process.env.NEXT_PUBLIC_COGNITO_SIGNOUT_URL || '';

    return (
      <>
        <Flex minH="100vh" direction="column">
          <Center flexGrow={1}>
            <Image
              src='https://omapajaportal.tndev.iggo.fi/img/adminmart/login/login-image-2.png?1616571917'
              w='600'
              h='500'
              alt='omapaja'
            />
            <Box color='black' p={5}>
              <Stack justify='center' align='center' spacing={10}>
                <Heading as='h1' fontSize='2xl' fontWeight='100'>{ t('home.loginTitle') }</Heading>
                <Text>{ t('home.loginSubtitle') }</Text>
                <Button
                  variant="solid"
                  colorScheme="#182868;"
                  size="sm"
                  onClick={() => signIn('cognito', { callbackUrl: '/dashboard' })}
                >
                { t('home.loginBtn') }
                </Button>
                <Link href={cognitoLogoutLink}>{ t('home.loginWithAnotherUserLink') }</Link>
                <Link href={cognitoLink}>
                  <Box fontSize='xs' _hover={{ color: 'blue.400' }}>
                   { t('home.registrationLink') }
                  </Box>
                </Link>
              </Stack>
            </Box>
          </Center>
          <Box w="100%" p={4}>
            <FooterBar />
          </Box>
        </Flex>
      </>
    );
  }
}

export default HomePage;
