// Core i18next library.
import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { getOptions } from './settings';

export const supportedLngs = {
  en: 'English',
  fi: 'Suomeksi',
};

i18n
  .use(resourcesToBackend((language, ns) => import(`./locales/${language}/${ns}.json`)))
  .use(LanguageDetector)
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    ...getOptions,
    detection: { caches: ['cookie'] },
  });

export default i18n;
